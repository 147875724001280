<template>
  <div>
    <playlists />
  </div>
</template>

<script>
import Playlists from '@/components/InternalRecords/Playlists.vue'

export default {
  components: { Playlists },
  name: 'Playlist',
}
</script>
